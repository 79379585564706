import React from 'react';
import Box from '@material-ui/core/Box';
import ContentSection from "../components/ContentSection";
import SEO from "../components/utility/SEO";
import IconList from "../components/IconList";
import GridContainer from "../components/GridContainer";
import {graphql} from "gatsby";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function BondGogglesQuoter({data}) {

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (!data || !data.prismicBondGogglesQuoterPage) return '';
  const {data: pageData} = data.prismicBondGogglesQuoterPage;

  function handleReverse(index) {
    return index % 2 === 0;
  }

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    alignSelf: 'center'
  };

  return (
      <>
        <SEO title={pageData.meta_title ? pageData.meta_title : "Bond goggles quoter"}/>
        <Box>
          <ContentSection
              imageBottom
              image={pageData.image ? pageData.image.url : null}
              title={pageData.title}
              subtitle={pageData.subtitle}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
          />
          {pageData.product_image &&
          <GridContainer gridItemProps={{xs: 12, md: 6}} style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}>
            <img src={pageData.product_image.url} alt=''
                 style={imageStyle}/>
          </GridContainer>
          }
          <IconList
              disableColors={pageData.feature_list_icons_backgroundcolor === 'Disabled'}
              iconTextArray={pageData.feature_list}
              style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
              singleColumn={matches}/>
          {pageData.body && pageData.body.map((content, index) => {
            if (!content.primary) return '';
            return (
                <ContentSection
                    reverse={handleReverse(index)}
                    key={index}
                    url={content.primary.url}
                    image={content.primary.image ? content.primary.image.url : null}
                    title={content.primary.title}
                    subtitle={content.primary.subtitle}
                    button={content.primary.button}
                    style={!matches ? {margin: '80px 0'} : {margin: '40px 0'}}
                />
            )
          })}
        </Box>
      </>
  );
}

export const bondGogglesQuoterPageQuery = graphql`
    query BondGogglesQuoterPage($id: String!) {
        prismicBondGogglesQuoterPage(id: {eq: $id}) {
            data {
                meta_title
                title
                subtitle
                image {
                    url
                }
                product_image {
                    url
                }
                feature_list_icons_backgroundcolor
                feature_list {
                    text
                    icon {
                        url
                    }
                }
                body {
                    ... on PrismicBondGogglesQuoterPageBodyContentSection {
                        primary {
                            title
                            subtitle
                            button
                            url
                            image {
                                url
                            }
                        }
                    }
                }
            }
        }
    }
`;
