import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  titleContainer: {
    paddingBottom: 0,
    paddingLeft: theme.spacing(2) * 3,
    marginBottom: '-60px'
  },
  iconContainer: {
    marginRight: theme.spacing(2),
    height: 36,
    width: 36,
    borderRadius: '22%',
    overflow: 'hidden',
    padding: 2
  },
  colorPrimary: {
    backgroundColor: theme.palette.primary.main
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.main
  },
  colorLightGray: {
    backgroundColor: theme.palette.grey[100],
  },
  icon: {
    height: '100%',
    width: '100%',
  },
  text: {
    wordWrap: 'break-word',
    alignSelf: 'center',
    lineHeight: 'initial',
    flex: 1,
    minHeight: 40,
    display: 'flex',
    alignItems: 'center'
  },
  gridItems: {
    flexWrap: 'initial',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}));

export default function IconList(props) {

  const {iconTextArray, titleText, singleColumn = false, disableColors = false, ...rest} = props;

  const classes = useStyles();

  function handleClass(index) {
    const elementClass = classes.iconContainer;
    let elementColor;
    if (disableColors) {
      elementColor = classes.colorLightGray;
    } else if (singleColumn) {
      if (index % 2 === 0) {
        elementColor = classes.colorPrimary;
      } else {
        elementColor = classes.colorSecondary;
      }
    } else {
      if (index % 4 === 0 || index % 4 === 3) {
        elementColor = classes.colorPrimary;
      } else {
        elementColor = classes.colorSecondary;
      }
    }
    return clsx(elementClass, elementColor)
  }

  const oddIconTextArray = [];
  const evenIconTextArray = [];
  if (iconTextArray) {
    iconTextArray.forEach((item, index) => {
      if (index % 2) {
        oddIconTextArray.push(item)
      } else {
        evenIconTextArray.push(item)
      }
    });
  }

  return (<>
      {titleText && <>
        <div className={classes.titleContainer}>
          <Typography variant={"subtitle1"} className={classes.text}>{titleText}</Typography>
        </div>
      </>}
      <Grid container className={classes.root} {...rest}>
        {iconTextArray && singleColumn && evenIconTextArray.map((items, index) => {
          return (
              <Grid xs={singleColumn ? 12 : 6} item container direction={"row"} alignItems={'center'}
                    className={classes.gridItems} key={index}>
                <div className={handleClass(index)}>
                  <img src={items.icon ? items.icon.url : null} alt='' className={classes.icon}/>
                </div>
                <Typography className={classes.text} variant={'subtitle1'}>
                  {items.text}
                </Typography>
              </Grid>
          )
        })}
        {iconTextArray && singleColumn && oddIconTextArray.map((items, index) => {
          return (
              <Grid xs={singleColumn ? 12 : 6} item container direction={"row"} alignItems={'center'}
                    className={classes.gridItems} key={index}>
                <div className={handleClass(index)}>
                  <img src={items.icon ? items.icon.url : null} alt='' className={classes.icon}/>
                </div>
                <Typography className={classes.text} variant={'subtitle1'}>
                  {items.text}
                </Typography>
              </Grid>
          )
        })}

        {iconTextArray && !singleColumn && iconTextArray.map((items, index) => {
          return (
              <Grid xs={singleColumn ? 12 : 6} item container direction={"row"} alignItems={'center'}
                    className={classes.gridItems} key={index}>
                <div className={handleClass(index)}>
                  <img src={items.icon ? items.icon.url : null} alt='' className={classes.icon}/>
                </div>
                <Typography className={classes.text} variant={'subtitle1'}>
                  {items.text}
                </Typography>
              </Grid>
          )
        })}
      </Grid>
  </>);
}
